<template>
  <div class="basic_setting">
    <!-- 库存设置 -->
    <div class="goods_div">
      <div class="goods_info">
        <DetailTitle
          class="mb-8"
          titleName="库存设置"
          des="在可订货数量的基础上设置展示给客户看的库存信息，未包含在库存区间内的将展示真实的库存）"
        />
        <div class="mb-8 px-16">
          <span class="mr-8">库存展示维度</span>
          <el-select v-model="stockShowMode" placeholder="请选择" size="small">
            <el-option
              v-for="item in stockOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span class="ml-8"
            >若按配件+库存分组设置，需要先创建库存分组
            <span
              class=" text-primary cursor-pointer"
              @click="$router.push('/goodsManage/stockGroup')"
              >去设置 ></span
            >
          </span>
        </div>
        <div class="stock-container px-16">
          <div style="max-height: 200px; overflow: auto">
            <div
              v-for="(item, index) in stockSetting.details"
              :key="index"
              style="
								display: flex;
								align-items: center;
								margin-bottom: 8px;
							"
            >
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="删除"
                placement="top"
              >
                <i
                  class="el-icon-delete del_logo"
                  @click="clearStockItem(index)"
                ></i>
              </el-tooltip>
              <div style="margin-right: 8px">可订货库存数量范围在</div>
              <el-input
                v-model="item.start"
                type="number"
                size="small"
                placeholder="-"
                style="flex: none; width: 90px"
                @change="onNumberInput('start', item)"
              />
              <div style="margin: 0 8px">至</div>
              <el-input
                v-model="item.end"
                type="number"
                size="small"
                placeholder="-"
                style="flex: none; width: 90px"
                @change="onNumberInput('end', item)"
              />
              <div style="margin: 0 8px">时库存展示为</div>
              <div
                style="
									display: flex;
									align-items: center;
									margin-left: 5px;
								"
              >
                <el-select v-model="item.type" size="small" style="">
                  <el-option
                    v-for="(type, index) in STOCK_TYPES"
                    :key="index"
                    :label="type.name"
                    :value="type.code"
                  />
                </el-select>
                <el-input
                  v-model="item.value"
                  v-show="item.type === 'specified'"
                  size="small"
                  maxLength="10"
                  style="flex: none; width: 80px"
                  @change="onNumberInput('value', item)"
                />
              </div>
            </div>
          </div>
          <el-button type="text" @click="addStockItem">添加库存区间</el-button>
        </div>
        <div class="mb-8 px-16 mt-8">
          <span class="mr-8">可订货库存数量不足起订量时</span>
          <el-select
            v-model="stockSetting.lessMinBuyQtyType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in stockLackOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="stockSetting.value"
            v-if="stockSetting.lessMinBuyQtyType === 'specified'"
            type="text"
            size="small"
            maxLength="10"
            style="flex: none; width: 80px;margin-left: 8px;"
          />
        </div>
        <div class="mb-8 px-16 mt-8">
          <span class="mr-8">是否展示0库存配件: </span>
          <el-select size="small" v-model="noZeroStock" placeholder="请选择">
            <el-option
              label="展示"
              :value="false">
            </el-option>
            <el-option
              label="不展示"
              :value="true">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 价格设置 -->
    <div class="goods_div">
      <div class="goods_info">
        <DetailTitle class="mb-8" titleName="价格展示设置" />
        <div class="mb-8 px-16">
          <span class="mr-8">指定公开展示价格类型</span>
          <el-select
            v-model="priceSetting.defaultPriceType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in priceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="stock-container">
          <div style="max-height: 200px; overflow: auto">
            <div
              v-for="(item, index) in priceSetting.details"
              :key="index"
              style="
								display: flex;
								align-items: center;
								margin-bottom: 8px;
							"
            >
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="删除"
                placement="top"
              >
                <i
                  class="el-icon-delete del_logo"
                  @click="clearPriceItem(index)"
                ></i>
              </el-tooltip>
              <div style="margin-right: 8px">展示价格范围在</div>
              <el-input
                v-model="item.start"
                type="number"
                size="small"
                placeholder="-"
                style="flex: none; width: 90px"
                @change="onNumberInput('start', item)"
              />
              <div style="margin: 0 8px">至</div>
              <el-input
                v-model="item.end"
                type="number"
                size="small"
                placeholder="-"
                style="flex: none; width: 90px"
                @change="onNumberInput('end', item)"
              />
              <div style="margin: 0 8px">时价格展示为</div>
              <div
                style="
									display: flex;
									align-items: center;
									margin-left: 5px;
								"
              >
                <el-select v-model="item.type" size="small" style="">
                  <el-option
                    v-for="(type, index) in PRICE_TYPES"
                    :key="index"
                    :label="type.name"
                    :value="type.code"
                  />
                </el-select>
                <el-input
                  v-model="item.value"
                  v-show="item.type === 'specified'"
                  size="small"
                  maxLength="10"
                  style="flex: none; width: 80px"
                  @change="onNumberInput('value', item)"
                />
              </div>
            </div>
          </div>
          <el-button type="text" @click="addPriceItem">添加价格范围</el-button>
        </div>
        <div class="mb-8 px-16 mt-8">
          <span class="mr-8">划线价</span>
          <el-select
            v-model="priceSetting.linePriceType"
            placeholder="请选择"
            size="small"
            @change="linePriceChange"
          >
            <el-option
              v-for="item in linePriceTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="priceSetting.value"
            size="small"
            style="margin-left: 8px;"
            v-if="priceSetting.linePriceType === 'price'"
          >
            <el-option
              v-for="(item, index) in priceOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-input
            v-model="priceSetting.value"
            v-if="priceSetting.linePriceType === 'specified'"
            type="text"
            size="small"
            maxLength="10"
            style="flex: none; width: 80px;margin-left: 8px;"
          />
        </div>
      </div>
    </div>
    <!-- 商品设置 -->
    <div class="goods_div">
      <div class="goods_info">
        <DetailTitle class="mb-8" titleName="商品展示设置" />
        <div class="mb-8 px-16">
          <span class="mr-8">商品图片展示</span>
          <el-select v-model="imageShowMode" placeholder="请选择" size="small">
            <el-option
              v-for="item in imageShowModeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 往来单位设置 -->
    <div class="goods_div">
      <div class="goods_info">
        <DetailTitle class="mb-8" titleName="往来单位设置" />
        <div class="mb-8 px-16">
          <span class="mr-8">往来单位审核方式</span>
          <el-radio-group v-model="autoAuditCoop">
            <el-radio :label="true">自动审核</el-radio>
            <el-radio :label="false">手动审核</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <div class="button-div">
        <el-button type="primary" size="small" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import DetailTitle from "@/components/manageCom/DetailTitle.vue";
import { SettingApi } from "./services";
const settingApi = new SettingApi();

const STOCK_TYPES = [
  { code: "original", name: "展示真实库存" },
  { code: "specified", name: "展示指定内容" },
  { code: "none", name: "展示无货" },
];
const PRICE_TYPES = [
  { code: "original", name: "展示真实价格" },
  { code: "specified", name: "展示指定内容" },
  { code: "none", name: "展示议价" },
];
export default {
  components: {
    DetailTitle,
  },
  name: "basic_setting",
  data() {
    return {
      PRICE_TYPES,
      stockShowMode: "part",
      imageShowMode: "erp",
      noZeroStock: false,
      stockSetting: {
        partNameRule: 0,
        details: [],
        allowZeroStock: true,
        checkStock: true, //检查库存
        autoConfirmOrder: false, //自动确认订单
        autoReceiveOrder: 15,
        stockShowMode: 0, //库存展示纬度
        allowZeroPrice: false, // 是否展示零价格配件，true: 展示;false: 不展示
        autoConfirmOfflinePay: false, // 订单线下支付财务审核方式，true: 自动;false: 手动
        showLinePrice: false, // 是否展示划线价
      },
      priceSetting: {
        details: [],
      },
      autoAuditCoop: null, //自动审核往来单位(客户)申请
      premiumRates: {}, //加价比例
      paymentTypeList: [],
      invoiceTypeList: [],
      priceTypeList: [],
      showFirstTab: false,
      tabs: [],
      active: 0,
      STOCK_TYPES,
      isErpUser: false, //是否是erp用户
      stockOptions: [
        {
          label: "按配件",
          value: "part",
        },
        {
          label: "按配件+库存分组",
          value: "partAndGroup",
        },
        {
          label: "按配件+仓库",
          value: "partAndWarehouse",
        },
      ],
      stockLackOptions: [
        {
          label: "原样展示/真实值",
          value: "original",
        },
        {
          label: "指定值",
          value: "specified",
        },
        {
          label: "不展示",
          value: "none",
        },
      ],
      linePriceTypeOptions: [
        {
          label: "最高价",
          value: "max",
        },
        {
          label: "指定价格类型",
          value: "price",
        },
        {
          label: "指定值",
          value: "specified",
        },
        {
          label: "不展示",
          value: "none",
        },
      ],
      imageShowModeOptions: [
        {
          label: "展示erp中图片",
          value: "erp",
        },
        {
          label: "展示平台上传的图片",
          value: "plat",
        },
        {
          label: "优先展示ERP",
          value: "firstErp",
        },
        {
          label: "优先展示平台",
          value: "firstPlat",
        },
        {
          label: "不展示",
          value: "none",
        },
      ],
      priceOptions: [
        {
          label: "平台价",
          value: "platform",
        },
        {
          label: "零售价",
          value: "retail",
        },
        {
          label: "批发价",
          value: "p",
        },
        {
          label: "批发价一",
          value: "p1",
        },
        {
          label: "批发价二",
          value: "p2",
        },
        {
          label: "批发价三",
          value: "p3",
        },
        {
          label: "批发价四",
          value: "p4",
        },
      ],
    };
  },
  watch: {
    // "$store.state.base": {
    //   handler: function(newValue) {
    //     this.isErpUser = newValue.erpType === 2 ? true : false;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  computed: {
    // 若当前用户是云配/修管家用户则库存信息-库存展示维度默认‘按配件’并隐藏库存维度展示
    stockShowModeVisible() {
      let visible = true;
      const { hasYP, hasYX } = this.$store.state.base.erp;
      if (hasYP || hasYX) {
        visible = false;
      }
      return visible;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    toStockGroup() {
      this.$router.push("/goodsManage/stockGroup");
    },
    initData() {
      //请求接口获取当前公司数据
      settingApi.getSettingDetail().then((res) => {
        this.noZeroStock = res.noZeroStock
        this.stockSetting = {
          ...res.stockSetting,
          details:
            res.stockSetting.details && res.stockSetting.details.length
              ? res.stockSetting.details.map((item) => ({ ...item }))
              : [
                  {
                    start: "0",
                    end: "",
                    value: "",
                    type: "original",
                  },
                ],
        };
        this.priceSetting = {
          ...res.priceSetting,
          details:
            res.priceSetting.details && res.priceSetting.details.length
              ? res.priceSetting.details.map((item) => ({ ...item }))
              : [
                  {
                    start: "0",
                    end: "",
                    value: "",
                    type: "original",
                  },
                ],
        };
        this.autoAuditCoop = res?.autoAuditCoop;
        this.stockShowMode = res.stockShowMode;
        this.imageShowMode = res.imageShowMode;
      });
    },
    toNumberFn(item, key) {
      var priceRate = Math.round(parseFloat(item[key] ? item[key] : 0) * 100);
      if (item[key].match(/\d+(\.\d{0,2})?/)) {
        item[key] = priceRate + "%";
      } else {
        item[key] = "0%";
      }
      this.$forceUpdate();
    },
    getpaymentTypeList() {
      let params = {
        type: "paymentType",
      };
      settingApi
        .getDictsByType(params)
        .then((res) => {
          this.paymentTypeList = res;
          if (!this.stockSetting.customerSetting.paymentType) {
            this.stockSetting.customerSetting.paymentType = this.paymentTypeList[0].Code;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getinvoiceTypeList() {
      let params = {
        type: "invoiceType",
      };
      settingApi
        .getDictsByType(params)
        .then((res) => {
          this.invoiceTypeList = res;
          if (!this.stockSetting.customerSetting.invoiceType) {
            this.stockSetting.customerSetting.invoiceType = this.invoiceTypeList[0].Code;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getpriceTypeList() {
      let params = {
        type: "priceType",
      };
      settingApi
        .getDictsByType(params)
        .then((res) => {
          this.priceTypeList = res;
          if (!this.stockSetting.customerSetting.priceType) {
            this.stockSetting.customerSetting.priceType = this.priceTypeList[0].Code;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    toggle(tab) {
      //切换
      if (this.active != tab) {
        this.active = tab;
        this.showFirstTab = !this.showFirstTab;
      }
    },
    refrensh() {
      this.$forceUpdate();
    },
    addSettingList() {
      if (this.stockSetting.details.length >= 20) {
        this.$alert("最多只能添加20条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        //   先判断前面输入的数据是否有交叉，没有交叉再新增一条
        let isCross = this.checkIsCross();
        if (!isCross) {
          this.stockSetting.details.push({
            stockRangeStart: "",
            stockRangeEnd: "",
            upperLimit: "",
          });
        }
      }
    },
    deleteThisItem(index) {
      this.stockSetting.details.splice(index, 1);
    },

    checkIsCross(type) {
      let arr = [];
      const title = type === "price" ? "价格范围" : "库存区间";
      if (type === "price") {
        arr = [...this.priceSetting.details];
      } else {
        arr = [...this.stockSetting.details];
      }
      if (arr && arr.length > 0) {
        //循环判断是否有不符合规则的输入数据
        for (let item of arr) {
          if (!item.start && !item.end) {
            this.$alert(`${title}库存区间值不能全部为空`, "提示", {
              confirmButtonText: "确定",
            });
            return true;
          } else if (
            item.start &&
            item.end &&
            Number(item.start) >= Number(item.end)
          ) {
            this.$alert(`${title}开始值不能大于等于结束值`, "提示", {
              confirmButtonText: "确定",
            });
            return true;
          } else if (item.type === 5) {
            if (!item.value) {
              this.$alert(`${title}显示上限不能为空`, "提示", {
                confirmButtonText: "确定",
              });
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    },
    toFloatNumberFn(numObj) {
      //百分比转为小数
      var priceObj = {};
      for (let k in numObj) {
        let item = numObj[k].substr(0, numObj[k].length - 1);
        if (item.length == 1) {
          item = parseFloat("0.0" + item) + "";
        } else if (item.length == 2) {
          item = "0." + item;
        } else if (item.length > 2) {
          var intNum = item.substr(0, item.length - 2); //整数
          var floatNum = item.substr(item.length - 2); //小数
          item = intNum + "." + floatNum;
        }
        priceObj[k] = item;
      }
      return priceObj;
    },
    save() {
      const {
        autoAuditCoop,
        stockShowMode,
        imageShowMode,
        stockSetting,
        priceSetting,
        noZeroStock,
      } = this;
      stockSetting.details = stockSetting.details.map((item) => {
        if (item.start == "") {
          delete item.start;
        }
        if (item.end == "") {
          delete item.end;
        }
        return item;
      });
      priceSetting.details = priceSetting.details.map((item) => {
        if (item.start == "") {
          delete item.start;
        }
        if (item.end == "") {
          delete item.end;
        }
        return item;
      });
      settingApi
        .saveSetting({
          autoAuditCoop,
          stockShowMode,
          imageShowMode,
          stockSetting,
          priceSetting,
          noZeroStock
        })
        .then(() => {
          this.$message.success("修改设置成功!");
          this.initData();
        });
    },
    saveOrigin() {
      let isCross = false;
      isCross = this.checkIsCross();
      if (!isCross) {
        //for循环把参数变成int类型
        if (this.stockSetting.details && this.stockSetting.details.length > 0) {
          for (let item of this.stockSetting.details) {
            item.start = item.start || null;
            item.end = item.end || null;
            item.value = item.value || null;
          }
        }
        if (
          this.stockSetting.details &&
          (this.stockSetting.details.length <= 0 ||
            this.stockSetting.details.length > 50)
        ) {
          this.$alert("库存区间设置条数需大于0小于等于50条", "提示", {
            confirmButtonText: "确定",
          });
        } else {
          var premiumRates = { ...this.premiumRates };
          var priceObj = this.toFloatNumberFn(premiumRates);
          this.stockSetting.premiumRates = Object.assign({}, priceObj);

          if (this.stockSetting.customerSetting.autoAudit) {
            this.stockSetting.customerSetting.discount =
              this.stockSetting.customerSetting.discount !== ""
                ? this.stockSetting.customerSetting.discount
                : null;
          }
          let params = {
            ...this.stockSetting,
            autoReceiveOrder: Number(this.stockSetting.autoReceiveOrder),
          };
          settingApi
            .saveSetting(params)
            .then(() => {
              this.$message({
                message: "操作成功！",
                type: "success",
              });
              this.initData();
            })
            .catch((error) => {
              this.$message.error(error);
            });
        }
      }
    },
    addStockItem() {
      if (this.stockSetting.details.length >= 50) {
        this.$alert("最多只能添加50条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        //   先判断前面输入的数据是否有交叉，没有交叉再新增一条
        let isCross = this.checkIsCross();
        if (!isCross) {
          this.stockSetting.details.push({
            start: "0",
            end: "",
            value: "有货",
            type: "original",
          });
        }
      }
    },
    linePriceChange() {
      this.priceSetting.value = "";
    },
    addPriceItem() {
      if (this.priceSetting.details.length >= 50) {
        this.$alert("最多只能添加50条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        //   先判断前面输入的数据是否有交叉，没有交叉再新增一条
        let isCross = this.checkIsCross("price");
        if (!isCross) {
          this.priceSetting.details.push({
            start: "0",
            end: "",
            value: "议价",
            type: "original",
          });
        }
      }
    },
    clearStockItem(itemIndex) {
      this.stockSetting.details = this.stockSetting.details.filter(
        (item, index) => itemIndex !== index
      );
    },
    clearPriceItem(itemIndex) {
      this.priceSetting.details = this.priceSetting.details.filter(
        (item, index) => itemIndex !== index
      );
    },
    onDecrease(step) {
      if (Number(this.stockSetting.autoReceiveOrder || 0) > 1) {
        this.stockSetting.autoReceiveOrder =
          Number(this.stockSetting.autoReceiveOrder || 0) - step;
      }
    },
    onIncrease(step) {
      this.stockSetting.autoReceiveOrder =
        Number(this.stockSetting.autoReceiveOrder || 0) + step;
    },
    onNumberInput(type, item) {
      switch (type) {
        case "start":
          if (item.start && Number(item.start || 0) < 0) {
            item.start = 0;
          }
          break;
        case "end":
          if (item.end && Number(item.end || 0) < Number(item.start || 0)) {
            item.end = item.start ? Number(item.start) + 1 : 0;
          }
          break;
        case "value":
          break;
        case "autoReceiveOrder":
          if (Number(this.stockSetting.autoReceiveOrder || 0) < 1) {
            this.stockSetting.autoReceiveOrder = 1;
          }
          if (Number(this.stockSetting.autoReceiveOrder || 0) % 1 !== 0) {
            this.stockSetting.autoReceiveOrder = Math.floor(
              Number(this.stockSetting.autoReceiveOrder || 0)
            );
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.basic_setting {
  padding: 0 @padding-size-main;
  margin-top: @padding-size-main;
  background: #ffffff;
  margin-bottom: 70px;
  .goods_div {
    // margin-bottom: @margin-size-main;
    .goods_title {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      font-weight: 500;
      margin: @margin-size-main 0;
    }
    .goods_info {
      margin: @padding-size-main 0;
      /deep/.el-input__suffix {
        line-height: 40px;
      }
      .set_margin_bottom {
        margin-bottom: @margin-size-main;
      }
      .tip_ptn {
        display: flex;
        font-size: 14px;
        line-height: 30px;
        margin: @margin-size-main 0;
        // & input[type=radio] {
        //   opacity: 1;
        // }
      }

      .tip_stock {
        display: flex;
        align-items: center;
        margin-top: @margin-size-main;
        // margin-bottom: .5rem;

        .mt-3 {
          margin-top: 1rem !important;
        }
      }
      .radio-margin {
        margin: 0 @margin-size-main;
      }
      .el-radio-group label {
        margin-bottom: 0;
      }
      .tips_logo {
        color: #fa8c16;
        cursor: pointer;
      }
      .del_logo {
        color: #fa8c16;
        cursor: pointer;
        font-size: 18px;
        margin-right: 10px;
      }
      .stock-container {
        // margin: 0 15px 15px 15px;
        padding: 16px;
        text-align: left;
        width: 55rem;
        background: #f5f5f5;
        border-radius: 2px;
        /deep/input::-webkit-outer-spin-button,
        /deep/input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
      .customer-container {
        padding: @padding-size-main 0;
        width: 55rem;
        background: #f5f5f5;
        border-radius: 2px;
        .con-input {
          display: inline-block;
          margin-bottom: @margin-size-secondary;
          .title {
            width: 100px;
            display: inline-block;
            text-align: right;
            padding-right: @padding-size-secondary;
            box-sizing: border-box;
            &.expire::before {
              content: "*";
              color: #e10000;
            }
          }
          /deep/.el-input,
          /deep/.el-select {
            width: 224px;
          }
          /deep/.el-input__inner,
          /deep/.el-select__inner {
            border: 1px solid #d7d6d6;
            border-radius: 2px;
          }
        }
      }
      /deep/.el-input-number .el-input__inner {
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
      }
      .item_set {
        text-align: left;
        position: relative;
        margin-bottom: @margin-size-main;
        .el-switch {
          &.yes {
            position: relative;
            &::before {
              content: "是";
              position: absolute;
              left: 6px;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1;
              font-size: 12px;
              color: #ffffff;
            }
          }
          &.no {
            position: relative;
            &::after {
              content: "否";
              position: absolute;
              right: 6px;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1;
              font-size: 12px;
              color: #ffffff;
            }
          }
        }
      }
      .switch-yes {
        color: #ffffff;
        font-size: 12px;
        line-height: 20px;
        position: absolute;
      }
      .switch-no {
        color: #ffffff;
        font-size: 12px;
        position: absolute;
        line-height: 20px;
      }
      .tip_left {
        margin-left: 32px;
      }
    }
  }
  .footer_btn {
    position: fixed;
    width: 90%;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px rgba(217, 217, 217, 0.3);
    bottom: 8px;
    z-index: 99;
    .button-div {
      display: flex;
      justify-content: center;
      padding: 12px 0;
      border-top: 1px solid @border-color-base;
    }
  }
}
</style>
